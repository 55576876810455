import axios from 'axios';
import { errorLogger } from './errorLogging';

let apiToken: string | undefined;

const apiClient = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_AML_API_ENDPOINT
});

export const setToken = (token: string | undefined) => {
	apiToken = token;
	apiClient.interceptors.request.use(function (config) {
		if (apiToken) {
			config.headers.Authorization = `Bearer ${apiToken}`;
			config.headers.Accept = "application/json";
		}
		return config;
	});

	// Add a response interceptor to check for 2FA and handle errors
	apiClient.interceptors.response.use(
		function (response) {
			return Promise.resolve(response);
		},
		function (error) {
			// Log the error using our error logging service
			errorLogger.logError({
				message: `API Error: ${error.message}`,
				stack: error.stack,
				additionalData: {
					type: 'api',
					status: error.response?.status,
					url: error.config?.url,
					method: error.config?.method,
					data: error.response?.data,
				}
			});

			// If the response has 2fa_required: true, perform an action
			if (
				error.response
				&& error.response.data
				&& error.response.data.require_2fa
				&& error.response.data.require_2fa === true
			) {
				handle2FARequired();
				return Promise.reject(error);
			}
			
			return Promise.reject(error);
		}
	);
};

// Janky ass shit
// This allows for the custom function settings to handle 2fa failtures
let handle2FARequired: () => void = () => {};
export const set2FAHandler = (handler: () => void) => {
	handle2FARequired = handler;
};

export const removeToken = () => {
	apiToken = undefined;
};

export default apiClient;