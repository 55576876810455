import React from 'react';
import ThemeConfig from './theme';
import NotistackProvider from 'components/NotistackProvider';
import RtlLayout from './components/RtlLayout';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import Router from './routes';
import AppContextWrapper from 'contexts/appContext';
import { LocalizationProvider, MobileDatePicker } from '@material-ui/lab'
import DateAdapter from '@material-ui/lab/AdapterMoment';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <AppContextWrapper>
        <ThemeConfig>
          <ThemePrimaryColor>
            <ThemeLocalization>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <RtlLayout>
                  <NotistackProvider>
                    <Router />
                  </NotistackProvider>
                </RtlLayout>
              </LocalizationProvider>
            </ThemeLocalization>
          </ThemePrimaryColor>
        </ThemeConfig>
      </AppContextWrapper>
    </ErrorBoundary>
  );
}

export default App;
