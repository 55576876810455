import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Box, Typography, Button, Container, Stack } from '@material-ui/core';
import { errorLogger } from '../services/errorLogging';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    errorLogger.logComponentError(error, errorInfo);
    this.setState({ errorInfo });
  }

  private handleReset = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
  };

  private handleHomeRedirect = () => {
    window.location.href = '/';
  };

  public render() {
    if (this.state.hasError) {
      // Use custom fallback if provided
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <Container>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            textAlign="center"
            py={5}
          >
            <Typography variant="h1" color="error" gutterBottom>
              Oops!
            </Typography>
            <Typography variant="h5" color="textSecondary" paragraph>
              Something went wrong. We're sorry for the inconvenience.
            </Typography>
            {process.env.NODE_ENV === 'development' && (
              <Typography variant="body2" color="textSecondary" paragraph>
                Error: {this.state.error?.message}
              </Typography>
            )}
            <Stack direction="row" spacing={2} mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleReset}
                size="large"
              >
                Try Again
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleHomeRedirect}
                size="large"
              >
                Return to Home
              </Button>
            </Stack>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 