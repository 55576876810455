interface ErrorDetails {
  message: string;
  stack?: string;
  componentStack?: string;
  additionalData?: Record<string, unknown>;
}

class ErrorLoggingService {
  private static instance: ErrorLoggingService;
  
  private constructor() {
    // Initialize error monitoring service if needed
    this.setupGlobalHandlers();
  }

  public static getInstance(): ErrorLoggingService {
    if (!ErrorLoggingService.instance) {
      ErrorLoggingService.instance = new ErrorLoggingService();
    }
    return ErrorLoggingService.instance;
  }

  private setupGlobalHandlers() {
    // Handle promise rejections
    window.addEventListener('unhandledrejection', (event) => {
      this.logError({
        message: `Unhandled Promise Rejection: ${event.reason}`,
        stack: event.reason?.stack,
        additionalData: { type: 'unhandledRejection' }
      });
    });

    // Handle runtime errors
    window.addEventListener('error', (event) => {
      this.logError({
        message: event.message,
        stack: event.error?.stack,
        additionalData: { 
          type: 'runtime',
          filename: event.filename,
          lineNo: event.lineno,
          colNo: event.colno
        }
      });
    });
  }

  public logError(error: ErrorDetails) {
    // Log to console in development
    if (process.env.NODE_ENV === 'development') {
      console.error('Error logged:', error);
    }

    // Here you would typically send to your error monitoring service
    // Example with a hypothetical error monitoring service:
    // errorMonitoringService.captureError({
    //   ...error,
    //   timestamp: new Date().toISOString(),
    //   environment: process.env.NODE_ENV,
    //   userAgent: navigator.userAgent,
    // });
  }

  public logComponentError(error: Error, errorInfo: React.ErrorInfo) {
    this.logError({
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      additionalData: { type: 'react' }
    });
  }
}

export const errorLogger = ErrorLoggingService.getInstance(); 